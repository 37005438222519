<template>
  
  <div class="w-full h-[100px] flex justify-center items-center">
      Hello Tailwind CSS 技术博客
  </div>
  <footer class="bg-white rounded-lg shadow dark:bg-gray-900 m-4">
    <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">备案信息: <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2022025610号</a></span>
    </div>
  </footer>
</template>